import React from 'react';
import { render } from 'react-dom';

import { ready } from 'core-utilities';

import App from './App';
import PrivateServerList from './containers/PrivateServerList';

import '../../../css/serverList/serverList.scss';

const serverListContainerId = 'running-game-instances-container';

ready(() => {
  if (document.getElementById(serverListContainerId)) {
    render(<App />, document.getElementById(serverListContainerId));
  }
});
