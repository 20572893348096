import { EnvironmentUrls } from 'Roblox';
import { Tracker, Configuration, TrackerRequest } from '@rbx/event-stream';

const robloxSiteDomain = EnvironmentUrls.domain;

export enum PrivateServerEventType {
  PRIVATE_SERVER_JOIN = 'privateServerJoin',
  PRIVATE_SERVER_LOAD = 'privateServerLoad'
}

export enum PrivateServerEventContext {
  GAME_TAB = 'gameTab'
}

const defaultConfiguration = new Configuration({
  baseUrl: `https://ecsv2.${robloxSiteDomain}/www`
});
const tracker = new Tracker(defaultConfiguration);

export interface TrackerClient {
  sendEvent(eventType: string, context: string, profileUserId: string): HTMLImageElement;
}

const trackerClient: TrackerClient = {
  sendEvent(eventType: PrivateServerEventType, context: string, latency: string) {
    const request: TrackerRequest = {
      target: 'www',
      localTime: new Date(),
      eventType,
      context,
      additionalProperties: {
        latency
      }
    };
    return tracker.sendEventViaImg(request);
  }
};

export default trackerClient;
