import { DeviceMeta, GameLauncher, EventStream } from 'Roblox';
import { jsClientDeviceIdentifier, authenticatedUser } from 'header-scripts';
import { start, endSuccess } from 'roblox-event-tracker';
import { urlService, uuidService } from 'core-utilities';

import attributionUtils from '../../../../ts/react/common/utils/attributionUtils';
import { EventType } from '../../../../ts/react/common/constants/eventStreamConstants';
import gameDataUtil from '../../gameData/utils/gameDetailMetaData';
import trackerClient, {
  PrivateServerEventContext,
  PrivateServerEventType
} from '../analytics/privateServerLogging';

const { isIE11 } = jsClientDeviceIdentifier;
const { getCurrentGameMetaData } = gameDataUtil;

const { deviceType, isUWPApp, isInApp, isChromeOs } = DeviceMeta();
export const getPlaceIdFromUrl = () => {
  const reg = /\/games\/(\d+)\//g;
  const match = reg.exec(window.location.pathname);
  return match ? +match[1] : null;
};

const shouldUseGameLaunchInterface = () => {
  return (
    (deviceType === 'computer' && !isUWPApp && !isChromeOs) ||
    (deviceType === 'tablet' && isIE11) ||
    isUWPApp
  );
};

export const getJoinScript = (placeId, { instanceId, accessCode }, serverListType) => {
  const startTime = performance.now();
  start(PrivateServerEventType.PRIVATE_SERVER_JOIN);
  const joinAttemptId = GameLauncher.isJoinAttemptIdEnabled()
    ? uuidService.generateRandomUuid()
    : undefined;
  const { gameDetailUniverseId: universeId } = getCurrentGameMetaData();
  const gamePlayIntentContext = `${serverListType}ServerListJoin`;
  const commonEventParams = {
    universeId,
    placeId,
    pid: placeId, // We send both placeId and pid since different tables might process either field
    joinAttemptId
  };

  const sendPlayGameClickedEvent = () => {
    EventStream.SendEventWithTarget(
      'playGameClicked',
      gamePlayIntentContext,
      {
        attributionId: attributionUtils.getAttributionId(EventType.GameDetailReferral),
        ...commonEventParams
      },
      EventStream.TargetTypes.WWW
    );
  };

  if (shouldUseGameLaunchInterface()) {
    return () => {
      sendPlayGameClickedEvent();
      EventStream.SendEventWithTarget(
        'gamePlayIntent',
        gamePlayIntentContext,
        {
          lType: 'protocol',
          refuid: null,
          pg: 'gameDetail',
          ...commonEventParams
        },
        EventStream.TargetTypes.WWW
      );

      // accessCode must be checked first in the case of joining a live private server instance
      if (accessCode) {
        trackerClient.sendEvent(
          PrivateServerEventType.PRIVATE_SERVER_JOIN,
          PrivateServerEventContext.GAME_TAB,
          performance.now() - startTime
        );
        endSuccess(PrivateServerEventType.PRIVATE_SERVER_JOIN);
        GameLauncher.joinPrivateGame(
          placeId,
          accessCode,
          undefined,
          joinAttemptId,
          GameLauncher.isJoinAttemptIdEnabled() ? gamePlayIntentContext : undefined
        );
        return;
      }

      if (instanceId) {
        trackerClient.sendEvent(
          PrivateServerEventType.PRIVATE_SERVER_JOIN,
          PrivateServerEventContext.GAME_TAB,
          performance.now() - startTime
        );
        endSuccess(PrivateServerEventType.PRIVATE_SERVER_JOIN);
        GameLauncher.joinGameInstance(
          placeId,
          instanceId,
          false,
          false,
          joinAttemptId,
          GameLauncher.isJoinAttemptIdEnabled() ? gamePlayIntentContext : undefined
        );
      }
    };
  }
  let url = '';
  if (isInApp) {
    url = urlService.getUrlWithQueries('/games/start', { placeId });
  } else {
    url = `robloxmobile://placeID=${placeId}`;
  }
  if (instanceId) {
    url += `&gameInstanceId=${instanceId}`;
  }

  if (accessCode) {
    url += `&accessCode=${accessCode}`;
  }

  if (GameLauncher.isJoinAttemptIdEnabled() && joinAttemptId) {
    url += `&joinAttemptId=${joinAttemptId}&joinAttemptOrigin=${gamePlayIntentContext}`;
  }

  return () => {
    sendPlayGameClickedEvent();
    window.location.href = url;
  };
};

export const canCreatePrivateGameServer = servers => {
  let { gameDetailPrivateServerLimit } = getCurrentGameMetaData();
  servers.forEach(({ owner }) => {
    if (owner.id === authenticatedUser.id) {
      gameDetailPrivateServerLimit -= 1;
    }
  });
  return gameDetailPrivateServerLimit > 0;
};
