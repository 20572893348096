import React, { Fragment, useEffect } from 'react';
import { renderToString } from 'react-dom/server';

import PropTypes from 'prop-types';
import { PriceLabel } from 'roblox-item-purchase';
import { Tooltip, Button } from 'react-style-guide';
import { withTranslations } from 'react-utilities';

import CreatePrivateGame from './CreatePrivateGame';
import GameListSection from '../components/GameListSection';
import gameDetailConstants from '../../gameData/constants/gameDetailConstants';
import serverListConstants from '../constants/serverListConstants';
import serverListService from '../services/serverListService';
import translationConfig from '../translation.config';
import urlConstants from '../constants/urlConstants';
import useCurrentTab from '../../gameData/hooks/useCurrentTab';
import useServerList from './useServerList';
import { canCreatePrivateGameServer } from '../util/gameInstanceUtil';

const { gameDetailTabs } = gameDetailConstants;
const { resources, serverListTypes } = serverListConstants;

function PrivateServerList({ translate, showServers, intl }) {
  const {
    clearServerAtIndex,
    hasError,
    hasNext,
    isBusy,
    loadMoreServers,
    metaData: {
      canCreateServer,
      placeId,
      placeName,
      price,
      privateServerProductId,
      sellerId,
      sellerName,
      universeId,
      userCanManagePlace
    },
    refreshServers,
    servers,
    setIsBusy
  } = useServerList(serverListService.getVipGameInstances, showServers);

  const currentTab = useCurrentTab();

  useEffect(() => {
    if (currentTab === gameDetailTabs.servers) {
      refreshServers();
    }
  }, [currentTab]);

  const canCreatePrivateServer = canCreatePrivateGameServer(servers);
  const doesGameSupportPrivateServers = privateServerProductId !== 0;

  const serversLink = `<a class="text-link" href="#!/game-instances">${translate(
    resources.serversText
  )}</a>`;

  const privateServerHelpLink = `<a class="text-link" href="${urlConstants.privateServerHelpUrl(
    intl.getRobloxLocale()
  )}">${translate(resources.privateServerHeader)}</a>`;

  return (
    <div id='rbx-private-servers' className='stack'>
      <div className='container-header'>
        <h2>{translate(resources.privateServerHeader)}</h2>

        {showServers && doesGameSupportPrivateServers && (
          <Button
            className='btn-more rbx-refresh refresh-link-icon'
            isDisabled={isBusy}
            onClick={() => refreshServers()}
            size={Button.sizes.extraSmall}
            variant={Button.variants.control}>
            {translate(resources.privateServerRefreshText)}
          </Button>
        )}

        <Tooltip
          content={translate(resources.privateServerTooltip)}
          id='private-server-tooltip'
          placement='bottom'>
          <span className='icon-moreinfo' />
        </Tooltip>
      </div>
      {!doesGameSupportPrivateServers ? (
        <div
          className='section-content-off'
          dangerouslySetInnerHTML={{
            __html: translate(resources.privateServersNotSupported, {
              vipServersLink: privateServerHelpLink
            })
          }}
        />
      ) : (
        <Fragment>
          <div className='create-server-banner section-content remove-panel'>
            <div className='create-server-banner-text text'>
              {canCreateServer && (
                <span
                  className='private-server-price'
                  dangerouslySetInnerHTML={{
                    __html: translate(resources.privateServerPrice, {
                      price: renderToString(<PriceLabel {...{ price }} />)
                    })
                  }}
                />
              )}
              <span className='play-with-others-text'>
                {translate(resources.privateServerPlayWithOthers)}
                <br />
                {!showServers && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: translate(resources.seeAllPrivateServersText, {
                        serversLink
                      })
                    }}
                  />
                )}
              </span>
            </div>
            {canCreateServer && (
              <CreatePrivateGame
                privateServerTranslate={translate}
                refreshServers={refreshServers}
                {...{
                  placeName,
                  universeId,
                  price,
                  canCreatePrivateServer,
                  sellerId,
                  sellerName,
                  productId: privateServerProductId
                }}
              />
            )}
          </div>
          <div className='section tab-server-only'>
            {showServers && (
              <GameListSection
                {...{
                  gameInstances: servers,
                  handleGameInstanceShutdownAtIndex: clearServerAtIndex,
                  isLoading: isBusy,
                  loadMoreGameInstances: loadMoreServers,
                  loadingError: hasError,
                  placeId,
                  setIsLoading: setIsBusy,
                  showLoadMoreButton: hasNext,
                  type: serverListTypes.Vip.key,
                  userCanManagePlace
                }}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}
PrivateServerList.defaultProps = {
  showServers: true
};
PrivateServerList.propTypes = {
  intl: PropTypes.shape({ getRobloxLocale: PropTypes.func.isRequired }).isRequired,
  showServers: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

export default withTranslations(PrivateServerList, translationConfig.privateServer);
